<template>
    <v-card hover class="ma-0 pa-0">
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;">   
            <v-row no-gutters>
                <v-col class="pt-1 pl-4 text-h5 font-weight-bold">콜리 추가</v-col>
            </v-row>        
        </v-card-title>

        <v-card-text class="mt-3 pb-0 pl-7 pr-7 pt-3">
            <v-row no-gutters>
                <v-col class="pb-0 title" style="color:#999999">콜리 이름<span style="color:red; padding-left:5px;">*</span></v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="pt-2 pb-4">
                    <v-text-field outlined hide-details :error="formStatus['user_name'].err" :ref="'user_name'" placeholder="이름을 입력해 주세요" v-model="formData.user_name" @keyup="checkChanged('user_name')" @change="checkChanged('user_name', true)">
                        <template v-slot:append v-if="formStatus['user_name'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{formStatus['user_name'].err_msg}}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="formData.device_type != 'LANDLINE'">
                <v-col class="pt-0 pb-0 title" style="color:#999999">휴대폰 번호<span style="color:red; padding-left:5px;">*</span></v-col>
            </v-row>
            <v-row no-gutters v-if="formData.device_type != 'LANDLINE'">
                <v-col class="pt-2 pb-4">
                    <v-text-field outlined hide-details :error="formStatus['user_mdn'].err" :ref="'user_mdn'" placeholder="휴대폰 번호를 입력해 주세요" v-model="formData.user_mdn" @keyup="checkChanged('user_mdn')" @change="checkChanged('user_mdn', true)">
                        <template v-slot:append v-if="formStatus['user_mdn'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{formStatus['user_mdn'].err_msg}}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>            
            <v-row no-gutters>
                <v-col class="pt-0 pb-0 title" style="color:#999999">비고</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="pt-2 pb-0">
                    <v-text-field outlined hide-details :error="formStatus['mng_memo'].err" :ref="'mng_memo'" placeholder="콜리에 대한 비고를 입력해 주세요" v-model="formData.mng_memo" @keyup="checkChanged('mng_memo')" @change="checkChanged('mng_memo', true)">
                        <template v-slot:append v-if="formStatus['mng_memo'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{formStatus['mng_memo'].err_msg}}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>            
            <v-row no-gutters v-if="landlineUseYn == 'Y'">
                <v-col class="pt-5 pb-0 title" style="color:#999999">
                    <v-radio-group class="mt-0" hide-details row v-model="formData.device_type">
                        <v-radio value="MOBILE">
                            <template v-slot:label >
                                <span style="font-size:18px;">무선단말</span>
                            </template>
                        </v-radio>
                        <v-radio value="LANDLINE">
                            <template v-slot:label>
                                <span style="font-size:18px;">유선단말</span>
                            </template>
                        </v-radio>
                    </v-radio-group>                                        
                </v-col>
            </v-row>
        </v-card-text>        
        <v-card-actions class="ma-0 pa-0">
            <v-row no-gutters>
                <v-col cols="12" class="pr-5 pb-6 pt-5 text-right">
                    <v-btn class="ma-2" width="130px" color="primary" text  v-on:click="closePopup()"><span style="color:#555555; font-size:1.4em;">취소</span></v-btn>
                    <v-btn class="ma-2 white--text" width="130px" color="#4573D5" large v-on:click="submitPopup()"><span style="font-size:1.4em;">확인</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>
<script>
import svcStaffApi from '@/api/svc_staff'
import { mapGetters } from 'vuex'

export default {
    data: () => ({
        formData: {
            user_name: '',
            user_mdn: '',
            mng_memo: '', 
            device_type: 'MOBILE',
        },        
        formStatus: {
            'user_name': {
                err: false,
                err_msg: '',
            },
            'user_mdn': {
                err: false,
                err_msg: '',
            },
            'mng_memo': { 
                err: false,
                err_msg: '',
            }
        },        
    }),
    computed: {
        ...mapGetters({
            staffList: 'svcStaff/staffList',
            landlineUseYn: 'login/getLandlineUseYn',
            sipHost: 'login/getSipHost',
            sipPort: 'login/getSipPort',
        }),
    },     
    mounted() {
        this.$nextTick(() => {
            this.$refs.user_name.focus()    
        })        
    },
    methods: {
        closePopup() { 
            this.$emit('cancel')
        },
        submitPopup() { 
            if(this.checkFormValid() == false){ return }
            
            let dupType = 'NONE'
            let userMdn = this.formData.user_mdn
            userMdn = userMdn.replaceAll("-", "").replaceAll(" ", "").replaceAll("*", "").replaceAll("+", "")
            if(this.formData.device_type != 'LANDLINE') {
                this.staffList.forEach(item => { 
                    if(item.user_mdn == userMdn) { 
                        dupType = 'mdn'
                    }
                })
                if(dupType != 'NONE') { 
                    this.$store.dispatch('openAlert', {
                        message: '이미 사용중인 휴대폰 번호 입니다',
                    })                  
                    return
                }
            }

            let va = this
            let reqForm = {
                'user_name': va.formData.user_name, 
                'user_mdn': userMdn,
                'mng_memo': va.formData.mng_memo,
                'device_type': va.formData.device_type,
            }

            if(this.formData.device_type == 'LANDLINE') { 
                va.$store.dispatch('openConfirm', {
                    message: '콜리 정보를 등록하시겠습니까?', 
                    va: va,
                    okCb: function(va) { 
                        va.$store.commit('svcStaff/addStaff', reqForm)
                        va.$store.dispatch('openAlert', {
                            va: va,
                            message: '추가 완료',
                            sub_message: '꼭! 상단의 저장버튼을 눌러야 변경사항이 저장됩니다.',
                            closeCb: function(va) { 
                                va.$emit('submit')
                            }
                        })
                    }
                })

            } else {
                svcStaffApi.checkDup({
                    user_mdn: userMdn,
                }, (dupType) => { 
                    if(dupType == 'NONE') { 
                        va.$store.dispatch('openConfirm', {
                            message: '콜리 정보를 등록하시겠습니까?', 
                            va: va,
                            okCb: function(va) { 
                                va.$store.commit('svcStaff/addStaff', reqForm)
                                va.$store.dispatch('openAlert', {
                                    va: va,
                                    message: '추가 완료',
                                    sub_message: '꼭! 상단의 저장버튼을 눌러야 변경사항이 저장됩니다.',
                                    closeCb: function(va) { 
                                        va.$emit('submit')
                                    }
                                })
                            }
                        })
                    } else {
                        va.$store.dispatch('openAlert', {
                            message: '이미 사용중인 휴대폰 번호 입니다',
                        })                    
                    }
                })
            }

        }, 
        checkChanged (t, focusOutFlag) {            
            if(this.formStatus[t]) { 
                if(this.formStatus[t].err || focusOutFlag) { 
                    this.checkFormValid(t)
                }
            }
        },
        checkFormValid (t) { 
            var succ = true
            if(!t || t == 'user_name') { 
                if(this.formData.user_name == '') { 
                    this.formStatus.user_name.err = true
                    this.formStatus.user_name.err_msg = '콜리 이름을 입력해 주세요'
                    succ = false
                } else {
                    this.formStatus.user_name.err = false
                    this.formStatus.user_name.err_msg = ''
                }
            }
            if(this.formData.device_type == 'LANDLINE') { 
                this.formStatus.user_mdn.err = false
                this.formStatus.user_mdn.err_msg = ''

            } else { 
                if(!t || t == 'user_mdn') { 
                    if(this.formData.user_mdn == '') { 
                        this.formStatus.user_mdn.err = true
                        this.formStatus.user_mdn.err_msg = '휴대폰번호를 입력해 주세요'
                        succ = false
                    } else {
                        this.formStatus.user_mdn.err = false
                        this.formStatus.user_mdn.err_msg = ''

                    }
                }
            }
            return succ
        }
    }
}
</script>
